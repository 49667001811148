import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

function Schema({
  schema,
  lang
}) {

  return (
    <Helmet
      defer={false}
      htmlAttributes = {
      {
        lang,
      }
    }>

      {
        (schema && schema.length) &&
        schema.map((el, index) => {
          if (el?.field_seo_schema_text_area) {
            return (
              <script type="application/ld+json"> 
                {el?.field_seo_schema_text_area}
              </script>
            )
          }
        })
      }
    </Helmet>
  )
}

Schema.defaultProps = {
  lang: `en`,
  Schema: []
}

Schema.propTypes = {
  lang: PropTypes.string,
  Schema: PropTypes.arrayOf(PropTypes.object),
}

export default Schema
