

export default function useDeviceDetect() {
  let isMobile = false

  // if (typeof window !== `undefined`) {
  //   const userAgent =
  //     typeof window.navigator === "undefined" ? "" : navigator.userAgent
  //   const mobile = Boolean(
  //     userAgent.match(
  //       /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
  //     )
  //   )
  //   // isMobile = window?.screen?.availWidth <= 1024;
  //   isMobile = mobile
  // }


  return { isMobile }
}
