import React from "react"
import { graphql, Link } from "gatsby"
import { navigate } from "gatsby";

import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"
import Schema from "../components/addon/schema"
import useDeviceDetect from "../service/useDeviceDetect"
import CustomLink from '../components/addon/Link'
import useScript from "../service/useScript"

const SpecialityList = ({ data }) => {
    const { isMobile } = useDeviceDetect()
    let allDoctors = data?.doctors?.nodes;
    //Sorting logic 
    allDoctors.sort(function(a, b){
        if(a.title < b.title) { return -1; }
        if(a.title > b.title) { return 1; }
        return 0;
    })
    allDoctors.sort((a, b) => {
        const x = a.field_head_of_department;
        const y = b.field_head_of_department;
        return (x === y)? 0 : x? -1 : 1;
    })

    const subSpecialities = data?.subSpecialities?.nodes;
    // if(subSpecialities && subSpecialities.length){
    //     subSpecialities.sort(function(a, b){
    //         if(a.name < b.name) { return -1; }
    //         if(a.name > b.name) { return 1; }
    //         return 0;
    //     })
    /// }
    subSpecialities.sort(function(a, b){
        if(a.field_weight < b.field_weight) { return -1; }
        if(a.field_weight > b.field_weight) { return 1; }
        return 0;
    })
    const details = data?.detail?.nodes[0];
    const content = details?.relationships?.content;
    const gallery = details?.relationships?.gallery;
    const banner = isMobile ? details?.relationships?.field_mobile_banner?.uri?.url : details?.relationships?.field_banner?.uri?.url;
    const breadcrumb = details?.relationships?.field_breadcrumb;

    const metaTagsForSpcl = details?.metatag
    const schema = details.relationships?.field_seo_schema ? details.relationships?.field_seo_schema : []

    allDoctors.sort((a, b) => {
        let aIndex = a?.relationships?.field_doc_weight_wrt_speciality.findIndex(el => el?.relationships?.field_speciality?.name === details?.name)
        let bIndex = b?.relationships?.field_doc_weight_wrt_speciality.findIndex(el => el?.relationships?.field_speciality?.name === details?.name)
        let x = a?.relationships?.field_doc_weight_wrt_speciality?.[aIndex]?.field_weight
        let y = b?.relationships?.field_doc_weight_wrt_speciality?.[bIndex]?.field_weight
        if(Number(x) < Number(y)) return -1
        if(Number(x) > Number(y)) return 1
        return 0
    })

    allDoctors.sort((a, b) => {
      let aIndex = a?.relationships?.field_doc_weight_wrt_speciality.findIndex(el => el?.relationships?.field_speciality?.name === details?.name)
      let bIndex = b?.relationships?.field_doc_weight_wrt_speciality.findIndex(el => el?.relationships?.field_speciality?.name === details?.name)
      let x = a?.relationships?.field_doc_weight_wrt_speciality?.[aIndex]?.field_weight === undefined ? 20 : a?.relationships?.field_doc_weight_wrt_speciality?.[aIndex]?.field_weight
      let y = b?.relationships?.field_doc_weight_wrt_speciality?.[bIndex]?.field_weight === undefined ? 20 : b?.relationships?.field_doc_weight_wrt_speciality?.[bIndex]?.field_weight
      return x - y
    });

    const doctors = allDoctors.slice(0, 3);
    const metaTags = data.allNodeTemplatePages.edges[0]?.node?.metatags ? data.allNodeTemplatePages.edges[0].node.metatags : []
    useScript("/assets/js/speciality-listing.js")
    useScript("/assets/js/allergy-detail.js")

    const onDoctorViewAll = () => {
        let url = '/find-a-doctor'
        if (breadcrumb && breadcrumb?.field_title) {
            url = url + '?category=' + encodeURIComponent(breadcrumb?.field_title)
        }
        navigate(url)
    }
    const showDrTitle = breadcrumb?.field_title === 'Physiotherapy & Rehabilitation' ? false : true;

    const defaultImage = "https://via.placeholder.com/84x84"
    return (
        <>
            <Layout>
                <Meta
                    files={{
                        js: [],
                        css: [
                            "/assets/css/services.css",
                            "/assets/css/services-detail.css",
                            "/assets/css/accessibility.css",
                        ],
                    }}
                    tags={metaTagsForSpcl}
                />
                <Schema schema={schema} />
                <main className="innerpage pt-first-section-with-breadcrumb">
                    {breadcrumb &&
                        <section className="breadcrumbs">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <ul>
                                            {breadcrumb?.field_breadcrumb_link?.map((el, index) => {
                                                     return  (
                                                        <li key={index}>
                                                            <CustomLink data={{link : el}}>{el.title}</CustomLink>
                                                        </li>
                                                    )
                                                })
                                            }
                                            <li><span>{breadcrumb?.field_title}</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </section>
                    }

                    {
                        typeof (banner) !== "undefined" && banner.length > 0 && <section className="inner_common_banner">
                            <div className="banner_img">
                                <img src={details?.relationships?.field_mobile_banner?.uri?.url} alt={details?.name} className="lazyload d-md-none" />
                                <img src={details?.relationships?.field_banner?.uri?.url} alt={details?.name} className="lazyload d-md-block d-none" />
                            </div>

                            <div className="banner_content text_left text_dark">
                                <div className="container">
                                    <h1>{details?.name}</h1>
                                    {details?.field_banner_sub_text && 
                                        <div dangerouslySetInnerHTML={{ __html: details?.field_banner_sub_text.processed }} />
                                    }
                                </div>
                            </div>
                        </section>
                    }
                    
                    <section className="speciality_section pt-5">
                        <div className="container">
                            <div className="row">
                                {doctors.length > 0 &&
                                    <div className="col-md-4">
                                        <div className="consultant_col">
                                            {/* <div className="head bg-white pt-0">
                                                <a href="javascript:;" className="back_arrow" onClick={() => navigate(-1)}></a>
                                                <h2>Consultants</h2>
                                            </div> */}
                                            <div className="doctors_list">
                                                {
                                                    doctors.length > 0 && (
                                                        doctors.map((el, ind) => {
                                                            const img = el?.relationships?.image?.uri?.url
                                                            return (
                                                                <div className="doctor_card">
                                                                    <div className="doctor_img">
                                                                        <span className="doctor_img_innercircle">
                                                                            {img ?
                                                                                <img src={img} alt={`Doctor_${ind}`} />
                                                                            : <img src={defaultImage} alt={el?.title} />}
                                                                        </span>
                                                                        <Link to={el?.path?.alias}>View Profile</Link>
                                                                    </div>
                                                                    <div className="doctor_detail">
                                                                        <h3 className="name">
                                                                            {/* {showDrTitle && `Dr. `} */}
                                                                            {el?.title}</h3>
                                                                        <p className="desig">{typeof (el?.field_designation) !== "undefined" && el?.field_designation !== null && el?.field_designation.length > 0 && el?.field_designation}</p>
                                                                        <p className="desig">{typeof (el?.field_qualifications) !== "undefined" && el?.field_qualifications !== null && el?.field_qualifications.length > 0 && el?.field_qualifications}</p>
                                                                        {((el?.field_not_avail_online_booking !== true) || (el?.field_year_of_experience)) &&
                                                                            <hr />
                                                                        }
                                                                        <p className="exp">{typeof (el?.field_year_of_experience) !== "undefined" && el?.field_year_of_experience !== null && el?.field_year_of_experience.length > 0 && el?.field_year_of_experience}</p>
                                                                        {el?.field_not_avail_online_booking !== true &&
                                                                            <p className="btn_wrap">
                                                                                <Link to={el?.path?.alias ? el?.path?.alias : "/"} className="btn btn-primary">Book Appointment</Link>
                                                                            </p>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    )
                                                }
                                            </div>
                                            {(allDoctors.length > 3) &&
                                                <div className="text-center my-4">
                                                    <a href="javascript:;" onClick={onDoctorViewAll} className="btn btn-primary">View All</a>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                                <div className={doctors && doctors.length > 0 ? 'col-md-8' : 'col-md-12'}>
                                    <h2 className="section-heading">{details?.name}</h2>
                                    {/* <div className="service_detail_banner blueBox">
                                        <div className="img_section">
                                            <img src={(details?.relationships && details.relationships.icon) ? details.relationships.icon.uri?.url : "/assets/images/icons/heart_care_white.svg"} alt={details?.name} />
                                        </div>
                                        <div className="text_section">
                                            <h2>{details?.name ? details?.name : "Hinduja Hospital"}</h2>
                                        </div>
                                    </div> */}

                                        {content &&
                                            <div className="accordion_container mb-4">
                                                <div
                                                    id="accordion"
                                                    role="tablist"
                                                    className="common_accordion specialty_accordion"
                                                >
                                                    {
                                                        content.length > 0 && content.map((el, index) => {
                                                            return (
                                                                <div className="card" key={index}>
                                                                    <div
                                                                        className="card-header"
                                                                        role="tab"
                                                                        id={`h-${index}`}
                                                                    >
                                                                        <h5 className="mb-0">
                                                                            <a
                                                                                className="collapsed"
                                                                                data-toggle="collapse"
                                                                                href={`#t-${index}`}
                                                                                aria-expanded="false"
                                                                                aria-controls={`t-${index}`}
                                                                            >
                                                                                {el?.field_title ? el?.field_title : "Hinduja Hospital"}
                                                                            </a>
                                                                        </h5>
                                                                    </div>
                                                                    <div
                                                                        id={`t-${index}`}
                                                                        className="collapse"
                                                                        role="tabpanel"
                                                                        aria-labelledby={`h-${index}`}
                                                                        data-parent="#accordion"
                                                                    >
                                                                        <div className="card-body" dangerouslySetInnerHTML={{ __html: el?.text?.processed }} />
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        }
                                        {gallery &&
                                            <div className="gallery_container">
                                                {/* <!-- MAIN SLIDES --> */}
                                                <div className="slider">
                                                    {(gallery && gallery.length > 0) && gallery.map((pic, index) => {
                                                            const img = pic?.uri?.url
                                                            return (
                                                                <div className="main_slide" key={index}>
                                                                    <figure>
                                                                        {" "}
                                                                        <img src={img} alt={`image${index}`} />
                                                                    </figure>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                {/* <!-- THUMBNAILS --> */}
                                                <div className="slider-nav-thumbnails">
                                                    {(gallery && gallery.length > 0) && gallery.map((item, index) => {
                                                            const img = item?.uri?.url
                                                            return (
                                                                <div className="thumb_slide">
                                                                    <figure>
                                                                        <img src={img} alt={`image__${index}`} />
                                                                    </figure>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        }

                                        {details?.description &&
                                            <div className="listsection_clinics content_wrap">
                                                <div dangerouslySetInnerHTML={{ __html: details?.description?.processed }}></div>
                                            </div>
                                        }

                                        {subSpecialities.length > 0 && (
                                            <div className="listsection_clinics content_wrap">
                                                <div className="cancer_offers">
                                                    <ul className="subspeciality_list d-flex justify-content-between">
                                                        {
                                                            subSpecialities.length > 0 && subSpecialities.map((el, ind) => {
                                                                return (
                                                                    <li key={ind} className="subspeciality_list_box">
                                                                        <Link to={el?.path?.alias ? el?.path?.alias : "/"}>
                                                                            <span className="heading-title pb-0">{el?.name ? el?.name : "Hinduja Hospital"}</span>
                                                                        </Link>
                                                                        {/* <span className="text-para">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span> */}
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        )}
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </Layout>
        </>
    )
}

export const query = graphql`
query SpecialityDetail($id: String) {
  allNodeTemplatePages(filter: {path: {alias: {regex: "/speciality$/"}}}) {
    edges {
      node {
        id
        title
        path {
          alias
        }
        relationships{
          field_seo_schema{
              field_seo_title
              field_seo_schema_text_area
          }
        }
        metatags: metatag_normalized {
          attributes {
            content
            name
            property
          }
        }
      }
    }
}

    doctors: allNodeDoctor(
      filter: {relationships: {field_speciality: {elemMatch: {id: {eq: $id}}}}}
    ) {
      nodes {
        id
        title
        path {
          alias
        }
        metatags: metatag_normalized {
          attributes {
            content
            name
            property
          }
        }
        firstName: field_first_name
        lastName: field_last_name
        relationships {
          image: field_dr_profile_image_for_list {
            id
            uri {
              value
              url
            }
            uri {
              value
              url
            }
          }
          field_speciality {
            id
            name
          }
          field_doc_weight_wrt_speciality {
            field_weight
            relationships {
              field_speciality {
                id
                name
                path {
                  alias
                }
              }
            }
          }
        }
        field_qualifications
        field_timeslots
        field_year_of_experience
        field_designation
        field_head_of_department
        field_not_avail_online_booking
      }
    }
    subSpecialities: allTaxonomyTermSpeciality(
      filter: {relationships: {parent: {elemMatch: {id: {eq: $id}}}}}
    ) {
      nodes {
        id
        name
        path {
          alias
        }
        field_weight
        relationships {
          field_breadcrumb {
            id
            field_title
            field_breadcrumb_link {
              uri
              title
            }
          }
          field_banner {
            uri {
              value
              url
            }
            uri {
              value
              url
            }
          }
          field_mobile_banner {
            uri {
              value
              url
            }
            uri {
              value
              url
            }
          }
        }
      }
    }
    detail: allTaxonomyTermSpeciality(filter: {id: {eq: $id}}) {
      nodes {
        id
        name
        path {
          alias
        }
        field_banner_sub_text {
          processed
        }
        description: field_description {
          processed
        }
        metatag: field_meta_tags{
          title
          description
          keywords
          canonical_url
          og_site_name
          og_description
          og_title
          og_image
        	og_url
          og_image_url
          twitter_cards_page_url
          twitter_cards_site
          twitter_cards_image
          twitter_cards_image_alt
          twitter_cards_description
          twitter_cards_title
          twitter_cards_creator
        }
        field_weight
        relationships {
          field_breadcrumb {
            id
            field_title
            field_breadcrumb_link {
              uri
              title
            }
          }
          field_banner {
            uri {
              value
              url
            }
            uri {
              value
              url
            }
          }
          field_mobile_banner {
            uri {
              value
              url
            }
            uri {
              value
              url
            }
          }
          icon: field_icon {
            id
            uri {
              value
              url
            }
            uri {
              value
              url
            }
          }
          content: field_content {
            __typename
            ...ParagraphTitleAndDescriptionSpecial
          }
        }
      }
    }
  }
  
  fragment ParagraphTitleAndDescriptionSpecial on paragraph__title_and_description {
    id
    field_title
    text: field_description {
      processed
    }
    field_cta_link {
      title
      uri
    }
    field_text {
      processed
    }
  }
`;

export default SpecialityList
